import React, { Component } from 'react';
import ElsaLoading from './ElsaLoading';

class ElsaTextDisplay extends Component {
	render() {
		const done = this.props.scriptShown;
		return (
            <p>{this.props.elsaScript}{!done && <ElsaLoading/>}</p>
		) 
	}
}

export default ElsaTextDisplay;
import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import LoggedInDocuments from './Documents/LoggedInDocuments';
import MenuContainer from "./PageNavigation/MenuContainer";
import DocumentMenu from "./PageNavigation/DocumentMenu";
import FullDocumentPreview from "./Documents/FullDocumentPreview";
import NewDocument from "./Documents/NewDocument";
import MainMenu from './PageNavigation/MainMenu';
import ProjectCard from "./Projects/ProjectCard";
import Settings from "./Settings/Settings";
import ProjectList from "./Projects/ProjectList";
import ClientCard from "./Clients/ClientCard";
import ClientList from "./Clients/ClientList";
import JobbieContainer from "./Jobbie/JobbieContainer";
import EditSetting from "./Settings/EditSetting";
import HudContainer from "./Hud/HudContainer";
import SettingWizard from "./Settings/SettingWizard";
//language settings
//default values file

class Main extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }


  render (){
    const props = {...this.props};
    const {username, days, deleteImportedDays} = props;
    const filterDocuments = (documents, cid) => {
      if (cid)
      {
        const filteredDocs = documents.filter((doc) => (doc.content.client && doc.content.client.cid === cid))
        return filteredDocs;
      }
      else
      {
        return false;
      }
    }
    return (
      <Container fluid>
      {props && props.fullPreview.doc && 
      <FullDocumentPreview 
        clientController={props.clientController}
        documentOptions={props.documentOptions}
        projectController={props.projectController}
        openFullPreview={props.fullPreview.open}
        closeFullPreview={props.fullPreview.close} 
        showPopup={props.showPopup}
        doc={props.fullPreview.doc ? props.fullPreview.doc : {}}
        lang={props.lang}
      />}
      {props.popup && 
      <JobbieContainer 
      lang={props.lang} 
      popup={props.popup}/>}
      
      {props.menu.document && 
      <DocumentMenu
      lang={props.lang}
      createNewDocument={props.createNewDocument}
      closeMenu={props.closeMenu}
      openMenu={props.openMenu}  
      />}
      
      {props.menu.settingWizard &&
      <SettingWizard
        fakturadetails={props.fakturadetails}
        lang={props.lang}
        editSetting={props.editSetting}
        closeMenu={props.closeMenu}
        openMenu={props.openMenu}  
      />
      }

      {props.editSetting.setting && 
      <EditSetting 
      lang={props.lang}
      editSetting={props.editSetting}/>}

      {props.menu.main && 
      <MainMenu
        clients={props.clients}
        closeMenu={props.closeMenu}
        createNewProject={props.createNewProject}
        createNewClient={props.createNewClient}
        documents={props.documents}
        editDocument={props.editDocument}
        openFullPreview={props.openFullPreview} 
        showPopup={props.showPopup}
        createNewDocument={props.createNewDocument}
        openMenu={props.openMenu}
        projectController={props.projectController}
        clientController={props.clientController}
        projects={props.projects}
        tasks={props.tasks}
        logout={props.logout}
        lang={props.lang} 
      />}
      <MenuContainer 
        projectController={props.projectController}
        clientController={props.clientController}
        clients={props.clients}
        createNewProject={props.createNewProject}
        createNewClient={props.createNewClient}
        closeMenu={props.closeMenu}
        documents={props.documents}
        editDocument={props.documentOptions.edit}
        openFullPreview={props.fullPreview.open} 
        showPopup={props.showPopup}
        createNewDocument={props.createNewDocument}
        openMenu={props.openMenu}
        projects={props.projects}
        repeatDocument={props.documentOptions.repeat}
        tasks={props.tasks}
        logout={props.logout}   
        lang={props.lang}           
        />
      {props && (props.documentController.newDocument.timelist || props.documentController.newDocument.faktura || props.documentController.newDocument.tilbud) && 
        <NewDocument
          creator={props.interface.creator}
          username={username}
          days={days}
          editSetting={props.editSetting}
          deleteImportedDays={deleteImportedDays}
          documentController={props.documentController}
          documentOptions={props.documentOptions}
          closeDocument={props.documentOptions.close} 
          clients={props.clients}
          openElsa={props.openElsa}
          projects={props.projects} 
          timelistdetails={props.timelistdetails}
          tilbuddetails={props.tilbuddetails}
          fakturadetails={props.fakturadetails}
          showPopup={props.showPopup}
          lang={props.lang} 
        />
      }
      {<HudContainer
        projectController={props.projectController}
        openFullPreview={props.fullPreview.open} 
        showPopup={props.showPopup}
        editDocument={props.editDocument} 
        repeatDocument={props.repeatDocument}
        displaySearchResult={props.displaySearchResult}
        uid={props.uid} 
        projects={props.projects}
        clients={props.clients}
        documents={props.documents}
        activeView={props.activeView}
        hud={props.hud}
        documentOptions={props.documentOptions}
        lang={props.lang} 
      />}
      <LoggedInDocuments 
        hud = {props.hud}
        editSetting={props.editSetting}
        openFullPreview={props.fullPreview.open}  
        projects={props.projects} 
        documents={props.documents} 
        documentOptions={props.documentOptions}
        lang={props.lang} 
        view={this.props.interface.timeline}
      />
      {props.selectedProject && <ProjectCard                 
        documents={props.selectedProject.projectDocuments} 
        documentController={props.documentController}
        documentOptions={props.documentOptions}
        editSetting={props.editSetting}
        lang={props.lang} 
        modifyProjectSetting={props.modifyProjectSetting}
        openFullPreview={props.fullPreview.open}
        pid={props.selectedProject ? props.selectedProject.pid : undefined}
        project={props.selectedProject} 
        projectController={props.projectController}
        showPopup={props.showPopup}
      />}
      {props.menu.projects && <ProjectList 
        closeMenu={props.closeMenu}
        createNewProject={this.props.createNewProject} 
        documentController={props.documentController}
        documentOptions={props.documentOptions}
        lang={props.lang} 
        projectController={this.props.projectController} 
        projects={this.props.projects} 
        showPopup={this.props.showPopup}
      />}
      {props.selectedClient && <ClientCard    
        cid={props.selectedClient ? props.selectedClient.cid : undefined}
        client={props.selectedClient} 
        clientController={props.clientController}              
        documents={filterDocuments(props.documents, props.selectedClient ? props.selectedClient.cid : undefined)}
        documentController={props.documentController}
        documentOptions={props.documentOptions}
        editSetting={props.editSetting}
        lang={props.lang} 
        openFullPreview={props.fullPreview.open}
        showPopup={props.showPopup}
      />}
      
      {props.menu.clients && <ClientList clientController={props.clientController} 
        clients={this.props.clients} 
        createNewClient={this.props.createNewClient} 
        showPopup={this.props.showPopup}
        documentController={props.documentController}
        documentOptions={props.documentOptions}
        closeMenu={props.closeMenu}
        lang={props.lang} 
      />}
    {props.menu.settings && <Settings 
      lang={this.props.lang}
      closeMenu={this.props.closeMenu}
      agrees={this.props.agrees}
      timelistdetails={props.timelistdetails}
      tilbuddetails={props.tilbuddetails}
      fakturadetails={props.fakturadetails}
      editSetting={props.editSetting}
      username={props.username}
      craft={props.craft}
        />}
    
        {
          //instrukcje
          //reklamy
          //livechat
          //projekt
          //Messenger
          //rewards
          //ustawienia
          //lists
        }
      </Container>
    );
  }
}

export default Main;

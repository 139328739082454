import React, { Component } from "react";
import { Container, Row, Form, Col, Button} from 'react-bootstrap';
import TimelistHeader from './TimelistHeader';
import TimelistRow from './TimelistRow';
import TimelistBasis from './TimelistBasis';
import {serverRouteParser, requestObjectGenerator} from '../ServerRoutes/serverRoute';
import { localizeDateStr, dateToSlashString } from "../Features/dateFeatures";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import TimelistRowMobile from "./TimelistRowMobile";
import ClientData from "../FormComponents/ClientData";
import ClientDataLocked from "../FormComponents/ClientDataLocked";
import WizardStepClient from "./WizardStepClient";
import WizardStepProject from "./WizardStepProject"
import { postkode } from "./postkode";
import './../wizards.css';
import NewTimelistWizardStepsHeader from "./NewTimelistWizardStepsHeader";
import NewTimelistWizardStepWorker from "./NewTimelistWizardStepWorker";
import NewTimelistWizardStepDocPositions from "./NewTimelistWizardStepDocPositions";
import DocumentPreview from "./DocumentPreview";
import TimelistPreview from "./TimelistPreview";
import { chooseLanguage } from "../Lang/langpack";

class NewTimelistWizard extends Component {

  render (){
    let {importDaysWindowOpen, step, stepOptions, extra, km, files, timelistPreview, timelistdetails, documentOptions, timelistnr, loggedin, sendFormToExecute, showClientData, savePost, loggedout, loggedday, deleteDay, saveDate, addNewRow, moveDown, moveUp, loggedpaidbreak, loggedbreak, loggeddaydescription, loggedDaysArray, clients, client, projects, clientData, editClientData, loggedprojectname, loggedname, writeBasisToState, writeClientToState, writeToState, isValid, lang} = this.props;
    const showKm = timelistdetails.km;
    const {clientname, address, postnr, post} = {...client};
    const {langinterface} = lang;
    const translation = {
      back: chooseLanguage('back', langinterface),
      next: chooseLanguage('next', langinterface),
      skip: chooseLanguage('skip', langinterface),
      import: chooseLanguage('import', langinterface),
    }
    return (
      <Container id='timelist' fluid>
          <Row>
            <Col>
              <NewTimelistWizardStepsHeader lang={lang} step={step} stepOptions={stepOptions}/>
            </Col>
          </Row>
          {step === 1 && <Row>
            <Col xs={12} lg={12}>
            {<WizardStepClient
              savePost={savePost} 
              client={client} 
              writeClientToState={writeClientToState}
              suggestions={clients}
              lang={lang}
            />}
            </Col>
          </Row>}
          {step === 2 && <Row>
            <Col xs={12} lg={12}>
            {<WizardStepProject
              projects={projects} 
              isValid={isValid}
              loggedprojectname={loggedprojectname} 
              writeBasisToState={writeBasisToState}
              lang={lang}
            />}
            </Col>
          </Row>}
          {step === 3 && <Row>
            <Col xs={12} lg={12}>
            {<NewTimelistWizardStepWorker
              isValid={isValid} 
              loggedname={loggedname} 
              writeBasisToState={writeBasisToState}
              lang={lang}
            />}
            </Col>
          </Row>}
          {step === 4 && <Row>
            <Col xs={12} lg={12}>
            {<NewTimelistWizardStepDocPositions
            loggedDaysArray={loggedDaysArray}
            saveDate={saveDate} 
            moveDown={moveDown} 
            moveUp={moveUp} 
            addNewRow={addNewRow} 
            timelist={{
              extra: extra,
              km: km,
              files: files,
              day: loggedday,
              in: loggedin,
              out: loggedout,
              paidbreak: loggedpaidbreak, 
              breakValue: loggedbreak,
              daydescription: loggeddaydescription,
            }}
            showKm={showKm}
            deleteDay={deleteDay} 
            writeToState={writeToState}  
            arrayLength={loggedDaysArray.length} 
            lang={lang}
            />}
            </Col>
          </Row>}
          {step === 5 && <TimelistPreview lang={lang} showKm={showKm} document={timelistPreview} sendFormToExecute={sendFormToExecute} documentOptions={documentOptions}/>}
          {step === 5 &&
            <div id='document-options-preview'>
            <DocumentExportOptions lang={lang} sendFormToExecute={sendFormToExecute}/>
          </div>
          }

          {step !== 5 &&
            <div id='document-options-preview'>
            <div id='new-document-export-options' className="knob-panel">
            <div className="option-knob" style={{display: step === 1 && "none"}}>
              <div className="clickable-panel" name="save" onClick={stepOptions.back}>
              </div>
              <p>{translation.back}</p>
              <div  className="document-export-ico"
                style={{
                backgroundImage: 'url("/arrow.svg")',
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                transform: 'rotate(180deg)',
                cursor: 'pointer'}}/>
            </div>
            <div className="option-knob" style={{display: step !== 4 && "none"}}>
              <div className="clickable-panel" name="save" onClick={importDaysWindowOpen}>
              </div>
              <p>{translation.import}</p>
              <div  className="document-export-ico"
                style={{
                backgroundImage: 'url("/time_plus.svg")',
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'}}/>
            </div>
            <div className="option-knob"  style={{display: step !== 1 && "none"}} >
              <div className="clickable-panel" name="save" onClick={stepOptions.next}>
              </div>
              <p>{translation.skip}</p>
              <div  className="document-export-ico"
                style={{
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'}}/>
            </div>
            <div className="option-knob"  style={{display: step === 5 && "none"}} >
              <div className="clickable-panel" name="save" onClick={stepOptions.next}>
              </div>
              <p>{translation.next}</p>
              <div  className="document-export-ico"
                style={{
                backgroundImage: 'url("/arrow.svg")',
                filter: "invert(1)",
                backgroundSize: 'contain',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer'}}/>
            </div>
          </div>
          </div>
          }
      </Container>
    );
  }
}

export default NewTimelistWizard;

import React, { Component } from "react";
import NewOffer from "./NewOffer";
import MinimizeButton from "./../FormComponents/MinimizeButton";
import CloseButton from "./../FormComponents/CloseButton";
import NewOfferWizard from "./NewOfferWizard";
import HelpButton from "../FormComponents/HelpButton";
import CreatorButton from "../FormComponents/CreatorButton";
import { postkode } from "./postkode";
import { chooseLanguage } from "../Lang/langpack";

class NewOfferContainer extends Component {
    constructor () {
        super();
        this.state = {
            step: 1,
          minimized: false,
          mode: 'creator',
          did: false,
          client: {
            clientname: "",
            address: "",
            postnr: "",
            post: "",
            email: "",
            mva: "",
          },
          loggedprojectname: '',
          pid: null,
          tilbudissue: null,
          tilbuddue: null,
          content: [{
            description: "",
            netprice: 0,
            multiplier: 1,
            vat: 0,
          }],
          position: [0],
          stapled: [],
          isValid: {projectName: true},
          editClientData: false,
      }
      this.minimizeDocument = this.minimizeDocument.bind(this);
      this.closeDocument = this.closeDocument.bind(this);
      this.maximizeDocument = this.maximizeDocument.bind(this);
      this.writeToState = this.writeToState.bind(this);
      this.deletePosition = this.deletePosition.bind(this);
      this.sendFormToExecute = this.sendFormToExecute.bind(this);
      this.addNewRow = this.addNewRow.bind(this);
      this.MoveUp = this.MoveUp.bind(this);
      this.MoveDown = this.MoveDown.bind(this);
      this.fillEditValues = this.fillEditValues.bind(this);
      this.writeClientToState = this.writeClientToState.bind(this);
      this.createStartValues = this.createStartValues.bind(this);    
    }

    
  editClientData = () => {
    let editClientData = this.state.editClientData;
    editClientData = !editClientData;
    this.setState({editClientData})
  }
  
  savePost = (postnr) => 
  {
      let stringPostNr = new String(postnr);
      console.log(stringPostNr)
      let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === postnr);
      console.log(selectedCity);
      let client = {...this.state.client};
      client['postnr'] = postnr;
      client['post'] = selectedCity && selectedCity[0][1] ? selectedCity[0][1] : stringPostNr.length < 4 ? '' : client['post'];
      this.setState({client});
  }

  writeToState(record, position, value){
      console.log("Record: " +  record + " Position: " + position + " Value: " + value);
      if(record === 'tilbudissue' || record === 'tilbuddue')
      {
        this.setState({
          [record] : new Date(value)
        })
      }
      else
      {
        let content = this.state.content;
        let newValue= content[position];
        console.log(content);
        console.log('newValue: ' + newValue);
        newValue[record] = value;
        content[position] = newValue;
        this.setState({
          content,
        })
      }
  }

  writeClientToState(record, value){
    console.log("Record: " +  record + " Value: " + value);
    const clients = this.props.clients;
    const findClient = clients.filter((client)=>client.clientname === value);
    if(!findClient[0])
    {
      let client = {...this.state.client};
      client[record] = value;
      this.setState({
        client,
      })
    }
    if(findClient[0] && findClient.length === 1)
    {
      let client = findClient[0];
      this.setState({
        client,
      })
    }
    if(findClient[0] && findClient.length > 1)
    {
      //
    }
}
  
sendFormToExecute(method) {
  //console.log('sending ...');
  let {did,
    loggedprojectname,
    pid,
    client,
    tilbudissue,
    tilbuddue,
    content,
    tilbudnr,
    stapled} = {...this.state}; 
    const {generate, save} = {...this.props.documentOptions};
  let fakturadetails = this.props.fakturadetails ? this.props.fakturadetails : {};
  const tilbud = {
      did: did,
      pid: pid,
      project: loggedprojectname,
      client: {...client},
      tilbudissue: tilbudissue,
      tilbuddue: tilbuddue,
      content: content,
      address: fakturadetails.address,
      companyname: fakturadetails.companyname,
      tilbudnr: tilbudnr,
      kontonr: fakturadetails.kontonr,
      mva: fakturadetails.mva,
      post: fakturadetails.post,
      postnr: fakturadetails.postnr,
      stapled: stapled, 
    }
    console.log(method);
    method === 'save' && save('tilbud', tilbud);
    method === 'create' && generate('tilbud', tilbud);
  }
    
  deletePosition(index)
  {
    let content = this.state.content;
    let position = this.state.position;
    content.splice(index, 1);
    position.splice(index, 1);

    this.setState({
      content,
      position,
    })
  }
  MoveUp(index)
  {
    //console.log('movingUp...')
    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  };
    let content = this.state.content;
    array_move(content, index, index-1)    
    this.setState({
      content,
    })
  }
  MoveDown(index)
  {
    //console.log('movingDown...')
    function array_move(arr, old_index, new_index) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  };
  let content = this.state.content;
    array_move(content, index, 1+index)    
    this.setState({
      content,
    })
  }
  addNewRow() { 
      const position = this.state.position;
      let content = this.state.content;
      const vat = this.props.fakturadetails.vat ?  +this.props.fakturadetails.vat : 25;
      const TilbudRow = {
        description: "",
        netprice: 0,
        multiplier: 1,
        vat,
      };
      position.push(position.length);
      content.push(TilbudRow);

     // console.log(days)
      this.setState({
        position,
        content
      })
  }
  fillEditValues(){
    const {creator} = this.props;
    const mode = creator ? 'creator' : 'page';
    let {content, client, tilbuddue, tilbudissue, project, pid, stapled} = this.props.editedDocument;
    let newContent = content.map((x,index) => {return {...x}});
    let {edit} = this.props;
    let tilbudnr = typeof edit === 'string' ? this.props.editedDocument.tilbudnr : this.props.tilbuddetails.tilbudnr;
    let docLength = content.length;
    let position = content.map((x,index) => {return index});
    let did = typeof edit === 'string' ? edit : false;
    this.setState({
      mode,
        did,
      position,
      content: newContent,
      client: client,
      tilbuddue,
      tilbudissue,
      tilbudnr,
      pid,
      loggedprojectname: project ? project : '',
      stapled: stapled ? stapled : [],
    })
  }
  createStartValues() {
    const {creator} = this.props;
    const mode = creator ? 'creator' : 'page';
    let {tilbuddetails, fakturadetails} = {...this.props};
    const vat = fakturadetails.vat ?  +fakturadetails.vat : 25;
    let dte = new Date();
    let tilbudissue = new Date();
    let content = [{
      description: "",
      netprice: 0,
      multiplier: 1,
      vat,
    }];
    let tilbudnr = tilbuddetails && tilbuddetails.tilbudnr;
    let due = tilbuddetails.tilbuddue ? tilbuddetails.tilbuddue : 14;
    let tilbuddue = dte.setDate(dte.getDate() + due);
    tilbuddue = new Date(tilbuddue);
    this.setState({
      tilbuddue, tilbudissue, tilbudnr, content, mode
    })
  }

  saveDate = (value, record) =>
  {
    const documentdue = this.props.tilbuddetails.tilbuddue ? this.props.tilbuddetails.tilbuddue : 14;
    if (record === 'tilbudissue')
    {
      let dte = new Date(value);
      const tilbudissue = new Date(value);
      
      const tilbuddue = record === 'tilbudissue' && documentdue ? new Date(dte.setDate(dte.getDate() + documentdue) ): new Date(dte.setDate(dte.getDate()+14));
      this.setState({
        tilbuddue,tilbudissue
      })
    }
    if (record === 'tilbuddue')
    {   
      this.setState({
       [record] : new Date(value)
      })
    }
  }

  writeBasisToState = (record, value) =>
  {
    this.setState({
      [record]: value
    })
  }

  componentDidMount() {
    if (this.props.edit)
    {
      this.fillEditValues();
    }
    else
    {
      this.createStartValues();
    }
  }

    nextStep = () => {
        let {step} = this.state;
        step = step < 5 ? ++step : step;
        this.setState({
            step: step,
        })
    }

    backStep = () => {
        let {step} = this.state;
        step = step > 1 ? --step : step;
        this.setState({
            step: step,
        })
    }

    chooseStep = (step) => {
        this.setState({
            step: step,
        })
    } 
  
    closeDocument(){
        this.props.showPopup('confirm', chooseLanguage('closePopup',this.props.lang.langinterface), () => this.props.closeDocument('tilbud'));

    }
    minimizeDocument(){
      this.setState({
          minimized: true,
         })
    }
  
    maximizeDocument(){
     this.setState({
      minimized: false,
     })
    }

    creatorModeOn = () => {
      const {modifyInterfaceSetting} = this.props.editSetting;
      modifyInterfaceSetting('creator', true);
        this.setState({
         mode: 'creator',
        })
       }

    pageModeOn = () => {
      const {modifyInterfaceSetting} = this.props.editSetting;
      modifyInterfaceSetting('creator', false);      
        this.setState({
         mode: 'page',
        })
       }
       
  
    render (){
        const props = {...this.props};
        const {edit} = props;
        const tilbudnr = typeof edit === 'string' ? props.editedDocument.tilbudnr : props.tilbuddetails.tilbudnr;
        const {step, mode} = this.state;
        const minimized = this.state.minimized;
        const today = new Date();
        let projects = [];
        this.props.projects.map((project, index)=>{
          if(!project.settings.archived || (project.settings.archived && !project.settings.archived.status) )
          {
            if(!project.settings.deleted || (project.settings.deleted && !project.settings.deleted.status) )
            {
              console.log(project.name + ': accepted')
              projects.push([project.name, project.pid]);
            }
          }
        });
        let client = this.state.client ? this.state.client : {};
        let {editClientData, loggedprojectname, isValid, tilbuddue, tilbudissue, content, position} = {...this.state};
        let clients = [];
        props.clients && props.clients.map((client, index)=>{
          if(!client.archived || (client.archived && !client.archived.status) )
          {
            if(!client.deleted || (client.deleted && !client.deleted.status) )
            {
              console.log(client.clientname + ': accepted')
              clients.push(client.clientname);
            }
          }
        });
        console.log(client);
        const tilbudPreview = {
            createdate: today,
            acceptdate: today,
            content: {              
              client: {...client},
              tilbudissue: tilbudissue,
              tilbuddue: tilbuddue,
              content: content, 
              tilbudnr: tilbudnr,
            },
            stapled: this.state.stapled,
            did: false,
            pid: this.state.pid,
            type: 'tilbud'
          }
          const {lang} = props;
          const {langinterface} = lang;
          const translation = {
            tilbud: chooseLanguage('tilbud', langinterface),
          }
        return (
        <>
            <div 
            style={{visibility: minimized ? 'hidden' : 'visible'}}
            >
                <div id="document-background">
                    <div id='tilbud-document'>
                        <div id='top-beam' className="tilbud-top-beam">
                            <h1>{translation.tilbud}{' #'}{tilbudnr}</h1>
                            <HelpButton openElsa={props.openElsa}/>  
                            <CreatorButton mode={mode} buttonAction={mode === 'creator' ? this.pageModeOn : this.creatorModeOn} color={1}/>
                            <MinimizeButton minimizeAction={this.minimizeDocument} />
                            <CloseButton closeAction={this.closeDocument}/>
                        </div>
                    {
                        mode === 'page' && <NewOffer 
                        client = {client}
                        arrayLength={content.length}
                        clients = {clients}
                        projects = {projects}
                        editClientData = {editClientData}
                        loggedprojectname = {loggedprojectname}
                        isValid = {isValid}
                        tilbuddue = {tilbuddue}
                        tilbudissue = {tilbudissue}
                        position = {position}
                        writeClientToState = {this.writeClientToState}
                        writeBasisToState = {this.writeBasisToState}
                        moveDown = {this.MoveDown}
                        moveUp = {this.MoveUp}
                        addNewRow = {this.addNewRow}
                        content = {content}
                        saveDate = {this.saveDate}
                        savePost = {this.savePost}
                        deletePosition = {this.deletePosition}
                        writeToState = {this.writeToState}
                        sendFormToExecute = {this.sendFormToExecute}
                        minimizeDocument={this.minimizeDocument} 
                        closeDocument={this.closeDocument}
                        tilbuddetails={props.tilbuddetails}
                        fakturadetails={props.fakturadetails ? props.fakturadetails : {}}
                        edit={props.edit}
                        editedDocument={props.editedDocument}
                        documentOptions={props.documentOptions}
                        generateDocument={props.generateDocument}
                        lang={lang}
                    />
                    }
                    {mode === 'creator' && <NewOfferWizard
                        stepOptions={{
                            choose: this.chooseStep,
                            back: this.backStep,
                            next: this.nextStep,
                        }
                        }
                        client = {client}
                        arrayLength={content.length}
                        clients = {clients}
                        projects = {projects}
                        editClientData = {editClientData}
                        loggedprojectname = {loggedprojectname}
                        isValid = {isValid}
                        tilbudPreview = {tilbudPreview}
                        tilbuddue = {tilbuddue}
                        tilbudissue = {tilbudissue}
                        position = {position}
                        writeClientToState = {this.writeClientToState}
                        writeBasisToState = {this.writeBasisToState}
                        moveDown = {this.MoveDown}
                        moveUp = {this.MoveUp}
                        addNewRow = {this.addNewRow}
                        content = {content}
                        saveDate = {this.saveDate}
                        savePost = {this.savePost}
                        deletePosition = {this.deletePosition}
                        writeToState = {this.writeToState}
                        sendFormToExecute = {this.sendFormToExecute}
                        step={step}
                        minimizeDocument={this.minimizeDocument} 
                        closeDocument={this.closeDocument}
                        tilbuddetails={props.tilbuddetails}
                        fakturadetails={props.fakturadetails ? props.fakturadetails : {}}
                        edit={props.edit}
                        editedDocument={props.editedDocument}
                        documentOptions={props.documentOptions}
                        generateDocument={props.generateDocument}
                        lang={lang}
                    />}
                    </div>
                </div>
            </div>
            <div 
            id='offer-minimized' 
            onClick={this.maximizeDocument} 
            style={{visibility: minimized ? 'visible' : 'hidden'}}
            >
            <p>{translation.tilbud}{' #'}{tilbudnr}</p>
            </div>
        </>
    );
  }
}

export default NewOfferContainer;

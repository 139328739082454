import { elsa } from "./ElsaParticlesContent";

const sceneplay = function (scene, lang){
   const scenes = {
        hello: ['hei_im_elsa', 'show_me_what_you_are_interest_in'],
        mainview: ['mainview_part1', 'mainview_part2'],
        appmenu: ['appmenu', 'appmenu_search', 'appmenu_addaday', 'appmenu_newdoc', 'appmenu_projects', 'appmenu_clients', 'appmenu_settings', 'appmenu_logout'],
        newdocument: ['newdoc_part1', 'newdoc_part2', 'newdoc_part3'],
        newinvoice: ['newdoc_fv_part0', 'newdoc_fv_part1', 'newdoc_fv_part2', 'newdoc_fv_part3', 'newdoc_fv_part4', 'newdoc_fv_part5', 'newdoc_fv_part6'],
        newbudget: ['newdoc_budget_part0', 'newdoc_budget_part1', 'newdoc_budget_part2', 'newdoc_budget_part3', 'newdoc_budget_part4', 'newdoc_budget_part5', 'newdoc_budget_part6'],
        newtimelist: ['newdoc_timelist_part0', 'newdoc_timelist_part1', 'newdoc_timelist_part2', 'newdoc_timelist_part3', 'newdoc_timelist_part4', 'newdoc_timelist_part5', 'newdoc_timelist_part6'],
        tltofv: ['newdoc_tl_to_fv'],
        tbtofv: ['newdoc_tb_to_fv'],
        fvtovarsel: ['fv_to_varsel'],
        fvtokreditnota: ['fv_to_kreditnota'],
        addaday: ['addaday', 'importday'],
        addclient: ['add_client'],
        addproject: ['add_project'],
        clientdelete: ['client_delete'],
        projectdelete: ['project_delete'],
        clientrecover: ['client_recover'],
        projectrecover: ['project_recover'],       
        savepdf: ['save_pdf'],
        printdocument: ['print_document'],
        settingsfakturadata: ['settings_fakturadata'],
        settingsfakturadue: ['settings_fakturadue'],
        settingsfakturanr: ['settings_fakturanr'],
        settingshourprice: ['settings_hourprice'],
        settingskmprice: ['settings_kmprice'],
        settingslanguage: ['settings_language'],
        settingsname: ['settings_name'],
        settingstilbuddue: ['settings_tilbuddue'],
        settingstilbudnr: ['settings_tilbudnr'],
        settingstimelistdefault: ['settings_timelistdefault'],
        settingstimelistnr: ['settings_timelistnr'],
        settingsvat: ['settings_vat'],
        settingspasschange: ['settings_passchange'],
        documentdelete: ['document_delete'],
        documentedit: ['document_edit'],
        documentrepeat: ['document_repeat'],
    
    }
    
    /* 
    const scenes = {
        hello: ['hei_im_elsa', 'show_me_what_you_are_interest_in'],
        flyers: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        boring: ['how_can_we_help_you', 'look_around_for_possibilities', 'id_love_to_tell_you_about_offer'],
        aboutus: ['about_lc', 'about_lc_minion_1', 'about_lc_minion_2', 'about_lc_endline'],
        kontakt: ['kontakt','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        prices: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        tilbud: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        packageprice: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        offer: ['id_love_to_tell_you_about_offer', 'look_around_for_possibilities','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        code: ['code_main', 'code_integration', 'test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        packages: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        website: ['website', 'test','test','test','website_modules','module_bookingcalendar','test','test','test','website_annualfee','test','test','test','test','test','test'],
        modules: ['module_bookingcalendar', 'test', 'test'], //wirtualna asystenkabooking calendar, zaawansowane formularze
        app: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        estore: ['estore', 'test', 'test', 'test', 'estore_annualfee'],
        graphicdesign: ['graphicdesign_main', 'logo', 'cards', 'carads', 'layouts','test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        logo: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        businesscard: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        foto: ['fotovideo_main', 'foto', 'fotosessions', 'fotosession_product', 'fotosession_portrait', 'fotosession_reports', 'fotosession_architecture', 'fotosession_interior', 'fotosession_family', 'fotosession_event', 'fotosession_drone', 'fotosession_quick', 'videosession', 'video_ads'],
        marketing: ['marketing_main', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness', 'test', 'test', 'marketingbranding', 'marketing_seo','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        supportit: ['supportit_main','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        search: ['test','test','test','test','test','test','test','test','test','test','test','test','test','test','test'],
        test: ['test'],
    }*/

    const scenario = scenes[scene];
    const requestedSceneplay = scenario.map((scene)=>{
        return  elsa(scene, lang);
    });
    return requestedSceneplay;
}

export {sceneplay};

function getButton(buttonType, lang) {
    const buttons = {
        continue: {
            text: {
                NO: 'Fortsett',
                PL: 'Kontynuuj',
                EN: 'Continue',
            },
            link: 'continue'
        },


        mainmenu: {
            text: {
                NO: 'Tilbake til hovedmenyen',
                PL: 'Powrót do głównego menu',
                EN: 'Back to main menu',
            },
            link: ['hello', 1]
        },

        newclient: {
            text: {
                NO: 'Lagre nye kundedata til databasen',
                PL: 'Dodawanie klienta do bazy danych',
                EN: 'Adding new client details to the database',
            },
            link: ['addclient', 0]
        },

        newproject: {
            text: {
                NO: 'Legge til et prosjekt i databasen',
                PL: 'Dodawanie projektu do bazy danych',
                EN: 'Adding a project to the database',
            },
            link: ['addproject', 0]
        },
        clientdelete: {
            text: {
                NO: 'Sletting av en klient',
                PL: 'Usunięcie klienta',
                EN: 'Deleting a client',
            },
            link: ['clientdelete', 0]
        },

        projectdelete: {
            text: {
                NO: 'Sletting av et prosjekt',
                PL: 'Usunięcie projektu',
                EN: 'Deleting a project',
            },
            link: ['projectdelete', 0]
        },
        clientarchive: {
            text: {
                NO: 'Klientarkivering',
                PL: 'Archiwizacja klienta',
                EN: 'Client archiving',
            },
            link: ['clientdelete', 0]
        },
        clientrecover: {
            text: {
                NO: 'Gjenopprett kunde',
                PL: 'Przywrócenie klienta',
                EN: 'Restoring the client',
            },
            link: ['clientrecover', 0]
        },
        projectrecover: {
            text: {
                NO: 'Prosjektet gjenopprette',
                PL: 'Przywrócenie projektu',
                EN: 'Restoring the project',
            },
            link: ['projectrecover', 0]
        },

        projectarchive: {
            text: {
                NO: 'Prosjektarkivering',
                PL: 'Archiwizacja projektu',
                EN: 'Project archiving',
            },
            link: ['projectdelete', 0]
        },
        printdocument: {
            text: {
                NO: 'Skrive ut et dokument',
                PL: 'Drukowanie dokumentu',
                EN: 'Printing a document',
            },
            link: ['printdocument', 0]
        },
        savepdf: {
            text: {
                NO: 'Lagring av dokumentet som pdf',
                PL: 'Zapisanie dokumentu jako pdf',
                EN: 'Saving a document as pdf',
            },
            link: ['savepdf', 0]
        },
        settingsfakturadata: {
            text: {
                NO: 'Datainnstillinger for fakturautsteder',
                PL: 'Ustawienia danych wystawcy faktury',
                EN: 'Invoice issuer data settings',
            },
            link: ['settingsfakturadata', 0]
        },
        settingsfakturadue: {
            text: {
                NO: 'Standard forfallsdato for faktura',
                PL: 'Ustawienia terminu płatności faktury',
                EN: 'Default invoice due date',
            },
            link: ['settingsfakturadue', 0]
        },
        settingsfakturanr: {
            text: {
                NO: 'Endring av fakturanummer',
                PL: 'Zmiana nr faktury',
                EN: 'Change of invoice number',
            },
            link: ['settingsfakturanr', 0]
        },
        settingshourprice: {
            text: {
                NO: 'Standard timepris',
                PL: 'Domyślna stawka godzinowa',
                EN: 'Default hourly rate',
            },
            link: ['settingshourprice', 0]
        },        
        settingskmprice: {
            text: {
                NO: 'Standard km-rate',
                PL: 'Domyślna kilometrówka',
                EN: 'Default km rate',
            },
            link: ['settingskmprice', 0]
        },
        settingslanguage: {
            text: {
                NO: 'Endring av språk',
                PL: 'Ustawienia języka',
                EN: 'Changing the language',
            },
            link: ['settingslanguage', 0]
        },   
        settingsname: {
            text: {
                NO: 'Endring av brukernavn',
                PL: 'Ustawienia nazwy użytkownika',
                EN: 'Changing username',
            },
            link: ['settingsname', 0]
        },
        settingspasschange: {
            text: {
                NO: 'Endring av passord',
                PL: 'Zmiana hasła użytkownika',
                EN: 'Changing password',
            },
            link: ['settingspasschange', 0]
        },
        settingstilbuddue: {
            text: {
                NO: 'Standard gyldighetsdato for tilbudet',
                PL: 'Domyślny termin ważności oferty',
                EN: 'Default budget expiration date',
            },
            link: ['settingstilbuddue', 0]
        }, 
        settingstilbudnr: {
            text: {
                NO: 'Endring av tilbudsdokumentnummer',
                PL: 'Zmiana nr oferty',
                EN: 'Change of budget number',
            },
            link: ['settingstilbudnr', 0]
        },
        settingstimelistdefault: {
            text: {
                NO: 'Standardverdier for tidsliste',
                PL: 'Domyślne wartości timelisty',
                EN: 'Default time sheet values',
            },
            link: ['settingstimelistdefault', 0]
        },   
        settingstimelistnr: {
            text: {
                NO: 'Endring av timelistenummer',
                PL: 'Zmiana nr listy czasu',
                EN: 'Change of time sheet number',
            },
            link: ['settingstimelistnr', 0]
        },
        settingsvat: {
            text: {
                NO: 'Standard mva-sats',
                PL: 'Domyślna stawka VAT',
                EN: 'Default VAT rate',
            },
            link: ['settingsvat', 0]
        },                             
        // ------- HELLO -----
        mainview: {
            text: {
                NO: 'Appens hovedvisning',
                PL: 'Widok główny aplikacji',
                EN: 'Main view of the app',
            },
            link: ['mainview', 0]
        },
        documentpreview: {
            text: {
                NO: 'Dokument forhåndsvisning',
                PL: 'Podgląd dokumentu',
                EN: 'Document preview',
            },
            link: ['mainview', 1]
        },
        documentopen: {
            text: {
                NO: 'Åpning av dokumentet',
                PL: 'Otwarcie dokumentu',
                EN: 'Document opening',
            },
            link: ['mainview', 1]
        },
        documentoptions: {
            text: {
                NO: 'Dokumentfunksjoner',
                PL: 'Opcje dokumentu',
                EN: 'Document options',
            },
            link: ['mainview', 1]
        },
        appmenu: {
            text: {
                NO: 'Hovedmenyoversikt',
                PL: 'Przegląd menu głównego',
                EN: 'Main menu overview',
            },
            link: ['appmenu', 0]
        },
        menu_search: {
            text: {
                NO: 'Søk',
                PL: 'Szukaj',
                EN: 'Search',
            },
            link: ['appmenu', 1]
        },
        menu_addaday: {
            text: {
                NO: 'Husk til en dag',
                PL: 'Dniówka',
                EN: 'Add a day',
            },
            link: ['appmenu', 2]
        },
        menu_newdoc: {
            text: {
                NO: 'Nytt dokument',
                PL: 'Nowy dokument',
                EN: 'New document',
            },
            link: ['appmenu', 3]
        },
        menu_projects: {
            text: {
                NO: 'Prosjekter',
                PL: 'Projekty',
                EN: 'Projects',
            },
            link: ['appmenu', 4]
        },
        menu_clients: {
            text: {
                NO: 'Kunder',
                PL: 'Klienci',
                EN: 'Clients',
            },
            link: ['appmenu', 5]
        },
        menu_settings: {
            text: {
                NO: 'Instilinger',
                PL: 'Ustawienia',
                EN: 'Settings',
            },
            link: ['appmenu', 6]
        },

        newdocument: {
            text: {
                NO: 'Lag et nytt dokument',
                PL: 'Tworzenie nowego dokumentu',
                EN: 'Make a new document',
            },
            link: ['newdocument', 0]
        },
        documentdelete: {
            text: {
                NO: 'Sletting av dokumentet',
                PL: 'Usunięcie dokumentu',
                EN: 'Deleting a document',
            },
            link: ['documentdelete', 0]
        },
        documentedit: {
            text: {
                NO: 'Redigering av dokumenter',
                PL: 'Edycja dokumentu',
                EN: 'Editing a document',
            },
            link: ['documentedit', 0]
        }, 
        documentrepeat: {
            text: {
                NO: 'Repetisjon av dokumentet',
                PL: 'Powtórzenie dokumentu',
                EN: 'Repeating a document',
            },
            link: ['documentrepeat', 0]
        },                
        newinvoice: {
            text: {
                NO: 'Lag en faktura',
                PL: 'Utwórz fakturę',
                EN: 'Create an invoice',
            },
            link: ['newinvoice', 0]
        },
        newbudget: {
            text: {
                NO: 'Lag en tilbud',
                PL: 'Utwórz oferty',
                EN: 'Create an budget',
            },
            link: ['newbudget', 0]
        },
        newtimelist: {
            text: {
                NO: 'Lag en timelist',
                PL: 'Utwórz listę godzin',
                EN: 'Create a timesheet',
            },
            link: ['newtimelist', 0]
        },
        tltofv: {
            text: {
                NO: 'Lage en faktura fra en tidsliste',
                PL: 'Utwórz fakturę z listy godzin',
                EN: 'Create an invoice from a timesheet',
            },
            link: ['tltofv', 0]
        },
        fvtovarsel: {
            text: {
                NO: 'Hvordan lage en varsel?',
                PL: 'Jak wystawić upomnienie?',
                EN: 'How to issue a reminder?',
            },
            link: ['fvtovarsel', 0]
        },
        fvtokreditnota: {
            text: {
                NO: 'Hvordan lage en kreditnota?',
                PL: 'Jak zrobić notę kredytową?',
                EN: 'How to make a credit note?',
            },
            link: ['fvtokreditnota', 0]
        },
        tbtofv: {
            text: {
                NO: 'Lage en faktura fra en tilbud',
                PL: 'Utwórz fakturę z dokumentu oferty',
                EN: 'Create an invoice from a budget',
            },
            link: ['tbtofv', 0]
        },
        addaday: {
            text: {
                NO: 'Hvordan huske timer?',
                PL: 'Jak zapisać dniówkę?',
                EN: 'How to save workday?',
            },
            link: ['addaday', 0]
        },
        importday: {
            text: {
                NO: 'Importer dager til tidslisten',
                PL: 'Importu dni do listy godzin',
                EN: 'Import days to the time sheet',
            },
            link: ['addaday', 1]
        },

        //KONTAKT

        kontakt: {
            text: {
                NO: 'Kontakt oss',
                PL: 'Kontakt',
                EN: 'Contact us',
            },
            link: ['kontakt', 0]
        },
        epost: {
            text: {
                NO: 'Skriv en e-post',
                PL: 'Napisz e-mail',
                EN: 'Write an e-mail',
            },
            link: 'epost'
        },
        kontaktskjema: {
            text: {
                NO: 'Kontaktskjema',
                PL: 'Formularz kontakt',
                EN: 'Contact form',
            },
            link: 'kontaktskjema'
        },
        tlf: {
            text: {
                NO: 'Ring 48 65 16 66',
                PL: 'Zadzwoń 48 65 16 66',
                EN: 'Call 48 65 16 66',
            },
            link: 'call'
        },
        newsletter: {
            text: {
                NO: 'Newsletter',
                PL: 'Newsletter',
                EN: 'Newsletter',
            },
            link: 'newsletter'
        },  
        facebook: {
            text: {
                NO: 'Følg oss på Facebook',
                PL: 'Odwiedź nas na Facebook',
                EN: 'Follow us on Facebook',
            },
            link: 'facebook'
        },
        instagram: {
            text: {
                NO: 'Se på Instagram',
                PL: 'Zobacz Instagram',
                EN: 'Check out Instagram',
            },
            link: 'instagram'
        },        
        linkedin: {
            text: {
                NO: 'LinkedIn',
                PL: 'LinkedIn',
                EN: 'LinkedIn',
            },
            link: 'linkedin'
        },  


    
    };
    const requestedButton = {
        text: buttons[buttonType]['text'][lang],
        link: buttons[buttonType]['link']
    };
    return requestedButton;
}


export {getButton};
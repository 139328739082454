import { getButton } from './ElsaParticlesButtons';


//------------- ELSA VIDEO IMPORT -------------

import add_day_to_timelist from './add_day_to_timelist.mp4'; 
import faktura from './faktura.mp4'; 
import menu from './menu.mp4'; 
import menu_search from './menu_search.mp4'; 
import menu_addaday from './menu_addaday.mp4'; 
import menu_clients from './menu_clients.mp4'; 
import menu_newdocument from './menu_newdocument.mp4'; 
import menu_projects from './menu_projects.mp4';
import menu_settings from './menu_settings.mp4';
import menu_logout from './menu_logout.mp4';
import new_document_part2 from './new_document_part2.mp4'; 
import tilbud from './tilbud.mp4'; 
import tilbud_to_faktura from './tilbud_to_faktura.mp4'; 
import timeline_part1 from './timeline_part1.mp4';
import timeline_part2 from './timeline_part2.mp4'; 

import faktura_part0 from './faktura_part0.mp4';
import faktura_part2 from './faktura_part2.mp4';
import faktura_part3 from './faktura_part3.mp4';
import faktura_part4 from './faktura_part4.mp4';
import faktura_part5 from './faktura_part5.mp4';
import faktura_part6 from './faktura_part6.mp4';

import tilbud_part0 from './tilbud_part0.mp4';
import tilbud_part2 from './tilbud_part2.mp4';
import tilbud_part3 from './tilbud_part3.mp4';
import tilbud_part4 from './tilbud_part4.mp4';
import tilbud_part5 from './tilbud_part5.mp4';
import tilbud_part6 from './tilbud_part6.mp4';

import timelist_part0 from './timelist_part0.mp4';
import timelist_part2 from './timelist_part2.mp4';
import timelist_part3 from './timelist_part3.mp4';
import timelist_part4 from './timelist_part4.mp4';
import timelist_part5 from './timelist_part5.mp4';
import timelist_part6 from './timelist_part6.mp4';
import timelist_to_faktura from './timelist_to_faktura.mp4'; 
import add_day from './add_day.mp4';

import add_client from './add_client.mp4'; 
import add_project from './add_project.mp4'; 
import client_delete from './client_delete.mp4'; 
import client_recover from './client_recover.mp4'; 
import print_document from './print_document.mp4'; 
import project_delete from './project_delete.mp4'; 
import project_recover from './project_recover.mp4';
import save_pdf from './save_pdf.mp4'; 
import settings_fakturadata from './settings_fakturadata.mp4'; 
import settings_fakturadue from './settings_fakturadue.mp4'; 
import settings_fakturanr from './settings_fakturanr.mp4'; 
import settings_hourprice from './settings_hourprice.mp4'; 
import settings_kmprice from './settings_kmprice.mp4'; 
import settings_language from './settings_language.mp4'; 
import settings_name from './settings_name.mp4'; 
import settings_tilbuddue from './settings_tilbuddue.mp4'; 
import settings_tilbudnr from './settings_tilbudnr.mp4'; 
import settings_timelistdefault from './settings_timelistdefault.mp4'; 
import settings_timelistnr from './settings_timelistnr.mp4'; 
import settings_vat from './settings_vat.mp4';
import settings_passchange from './settings_passchange.mp4'; 
import fv_to_kreditnota from './fv_to_kreditnota.mp4'; 
import fv_to_varsel from './fv_to_varsel.mp4'; 


import document_delete from './document_delete.mp4'; 
import document_edit from './document_edit.mp4'; 
import document_repeat from './document_repeat.mp4'; 

import print_varsel from './print_varsel.mp4'; 





//---------- ELSA FUNCTION ----------------- 
function elsa(sentence, lang){
    
    //---------- ELSA SENTENCES -------
    const elsaSentences = {
        add_client: {
            file: add_client,
            text: {
                NO: 'For å lagre kundedetaljer i databasen, velg kunder -> ny kunde fra menyen. I vinduet som vises fyller du inn kundenavnet som for- og etternavn eller firmanavn. Legg til adresse og postnummer. Hvis det gjelder et selskap, legg til MVA-nummeret. Bekreft lagring. Ved utfylling av dokumentet neste gang, vil kundens data bli foreslått etter å ha skrevet inn minst 3 tegn i navnefeltet.',
                PL: 'Aby zapisać w bazie danych szczegóły klienta wybierz z menu klienci -> nowy klient. W wyświetlonym oknie uzupełnij nazwę klienta jako imię i nazwisko lub nazwa firmy. Dodaj adres i kod pocztowy. W przypadku firmy dodaj nr MVA. Potwierdź zapis. Podczas kolejnego uzupełniania dokumentu dane klienta będą sugerowane po wpisaniu minimum 3 znaków w polu nazwy.',
                EN: 'To save the customer details in the database, select the clients -> new client from menu. In the window that appears, fill in the customer name as the first and last name or company name. Add the address and postal code. In the case of a company, add the MVA number. Confirm the save. When filling in the document again, the customer data will be suggested after entering at least 3 characters in the name field.',
            },
            type: 'client',
            buttons: [
                'clientdelete',
                'clientarchive',
                'newproject',
                'mainmenu',
            ]
        },
        add_project: {
            file: add_project,
            text: {
                NO: 'For å lagre prosjektdetaljer i databasen, velg prosjekter -> nytt prosjekt fra menyen. Fullfør prosjektnavnet i vinduet som vises. Bekreft lagring. Prosjektet er nå tilgjengelig i prosjektlisten. Du kan angi detaljer som start- og sluttid for arbeidet. Neste gang du fullfører dokumentet, vil prosjektdata bli foreslått etter å ha lagt inn minimum 3 tegn i prosjektfeltet.',
                PL: 'Aby zapisać w bazie danych szczegóły projektu wybierz z menu projekty -> nowy projekt. W wyświetlonym oknie uzupełnij nazwę projektu. Potwierdź zapis. Projekt jest od teraz dostępny na liście projektów. Możesz wprowadzić szczegóły jak godzina rozpoczęcia pracy i zakończenia. Podczas kolejnego uzupełniania dokumentu dane projektu będą sugerowane po wpisaniu minimum 3 znaków w polu projekt.',
                EN: 'To save the customer details in the database, select the clients -> new client from menu. In the window that appears, fill in the customer name as the first and last name or company name. Add the address and postal code. In the case of a company, add the MVA number. Confirm the save. When filling in the document again, the customer data will be suggested after entering at least 3 characters in the name field.',
            },
            type: 'project',
            buttons: [
                'projectdelete',
                'projectarchive',
                'newclient',
                'mainmenu',
            ]
        },
        project_recover: {
            file: project_recover,
            text: {
                NO: 'For å gjenopprette et arkivert eller slettet prosjekt, klikk på Prosjekter i hovedmenyen og velg Alle prosjekter. Velg kategorien Arkivert eller Slettet i det åpne vinduet. Fra listen velger du prosjektet hvis detaljer du vil gjenopprette. Finn panelet for prosjektalternativer og velg Gjenopprett til aktive prosjekter.',
                PL: 'Aby przywrócić zarchiwizowany lub usunięty projekt kliknij Projekty w menu głównym i wybierz Wszyskie projekty. W otwartym oknie wybierz zakładkę Zarchiwizowane lub Usunięte. Z listy wybierz projekt, którego szczegóły chcesz przywrócić. Znajdź panel Opcje projektu i wybierz Przywróć do aktywnych projektów.',
                EN: 'To restore an archived or deleted project, click Projects in the main menu and select All Projects. In the opened window, select the Archived or Deleted tab. From the list, select the project whose details you want to restore. Find the Project Options panel and select Restore to Active Projects.',
            },
            type: 'project',
            buttons: [
                'projectdelete',
                'projectarchive',
                'newclient',
                'mainmenu',
            ]
        },
        client_recover: {
            file: client_recover,
            text: {
                NO: 'For å gjenopprette en arkivert eller slettet klient, klikk på Kunder i hovedmenyen og velg Alle kunder. Velg kategorien Arkivert eller Slettet i det åpne vinduet. Fra listen velger du kunden hvis detaljer du vil gjenopprette. Finn panelet for Kundealternativer og velg Gjenopprett til aktive klienter.',
                PL: 'Aby przywrócić zarchiwizowanego lub usuniętego klienta kliknij Klienci w menu głównym i wybierz Wszyscy klienci. W otwartym oknie wybierz zakładkę Zarchiwizowane lub Usunięte. Z widocznej listy wybierz klienta, którego szczegóły chcesz przywrócić. Znajdź panel Opcje klienta i wybierz Przywróć do aktywnych klientów.',
                EN: 'To restore an archived or deleted client, click Clients in the main menu and select All Clients. In the opened window, select the Archived or Deleted tab. From the list, select the client whose details you want to restore. Find the Client Options panel and select Restore to Active Clients.',
            },
            type: 'client',
            buttons: [
                'clientdelete',
                'clientarchive',
                'newclient',
                'mainmenu',
            ]
        },
        client_delete: {
            file: client_delete,
            text: {
                NO: 'For å arkivere eller slette kundedetaljer, klikk på Kunder-knappen i menyen. Velg klienten du vil redigere fra listen. I vinduet som vises, i feltet Klientalternativer, er det funksjonene for arkivklienten og sletteklienten. Velg ønsket operasjon og bekreft utførelsen.',
                PL: 'W celu archiwizacji lub usunięcia szczegółów klienta kliknij przycisk Klienci w menu. Z listy wybierz klienta którego chcesz edytować. W wyświetlonym oknie w polu Opcje klienta znajdują się funkcje archwizuj klienta i usuń klienta. Wybierz żądaną operację i potwierdź wykonanie.',
                EN: 'To archive or delete client details, click the Clients button in the menu. Select the client you want to edit from the list. In the displayed window, in the Client Options field, there are the functions archive client and delete client. Select the desired operation and confirm execution.',
            },
            type: 'client',
            buttons: [
                'newclient',
                'clientrecover',
                'mainmenu',
            ]
        },
        project_delete: {
            file: project_delete,
            text: {
                NO: 'For å arkivere eller slette prosjekt, klikk på Prosjekter-knappen i menyen. Velg prosjektet du vil redigere fra listen. I vinduet som vises, i feltet Prosjektalternativer, er det funksjonene for arkivprosjekt og sletteprosjekt. Velg ønsket operasjon og bekreft utførelsen.',
                PL: 'W celu archiwizacji lub usunięcia projektu kliknij przycisk Projekty w menu. Z listy wybierz projekt który chcesz edytować. W wyświetlonym oknie w polu Opcje projektu znajdują się funkcje archwizuj projekt i usuń projekt. Wybierz żądaną operację i potwierdź wykonanie.',
                EN: 'To archive or delete a project, click the Projects button in the menu. From the list, select the project you want to edit. In the displayed window, in the Project Options field, you will find the functions archive project and delete project. Select the desired operation and confirm execution.',
            },
            type: 'project',
            buttons: [
                'newproject',
                'projectrecover',
                'mainmenu',
            ]
        },
        print_document: {
            file: print_document,
            text: {
                NO: 'I hovedprogramvisningen, fra dokumentlisten, åpne dokumentet du vil skrive ut. Velg skriv ut pdf fra dokumentalternativene. Du kan nå skrive ut den nedlastede pdf-filen ved hjelp av skriveren.',
                PL: 'W widoku głównym z listy dokumentów otwórz dokument do wydrukowania. Z opcji dokumentu wybierz drukuj pdf. Pobrany plik pdf możesz teraz wydrukować za pomocą drukarki.',
                EN: 'In the main view, from the document list, open the document to print. From the document options, select print pdf. You can now print the downloaded pdf file using your printer.',
            },
            type: 'document',
            buttons: [
                'documentopen',
                'newdocument',
                'mainview',
                'mainmenu',
            ]
        },
        save_pdf: {
            file: save_pdf,
            text: {
                NO: 'I hovedprogramvisningen, fra dokumentlisten, åpne dokumentet du vil lagre. Velg skriv ut pdf fra dokumentalternativene. Dette vil laste ned dokumentet som en pdf til harddisken din.',
                PL: 'W widoku głównym aplikacji z listy dokumentów otwórz dokument do zapisania. Z opcji dokumentu wybierz drukuj pdf. Spowoduje to pobranie dokumentu jako pdf na Twój dysk twardy.',
                EN: 'In the main view of the application, from the list of documents, open the document to save. From the document options, select print pdf. This will download the document as a pdf to your hard drive.',
            },
            type: 'document',
            buttons: [
                'documentopen',
                'printdocument',
                'mainview',
                'mainmenu',
            ]
        },
        settings_fakturadata: {
            file: settings_fakturadata,
            text: {
                NO: 'For å redigere fakturadetaljene dine, klikk på innstillinger i hovedmenyen. I fanen Konto finner du panelet Fakturadata. Alternativene inkluderer navn, MVA-nummer, adresse og firmakontonummer. Oppgitte data vil bli inkludert i fakturaen, låneseddelen og tilbudsdokumentet opprettet ved hjelp av programmet.',
                PL: 'W celu edycji danych wystawiającego faktury w menu głównym kliknij ustawienia. W zakładce Konto znajdź panel Dane do faktury. Zawarte są tam opcje nazwy, numeru MVA, adresu i nr konta firmy. Podane dane będą umieszczone na dokumencie faktury, kreditnoty i oferty utworzonych za pomocą programu.',
                EN: 'To edit invoice data, click Settings in the main menu. In the Account tab, find the Invoice Data panel. It contains options for the name, MVA number, address, and company account number. The data you provide will be placed on the invoice, credit note, and offer document created using the program.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturanr',
                'settingsfakturadue',
                'newinvoice',
                'mainmenu',
            ]
        }, 
        settings_fakturadue: {
            file: settings_fakturadue,
            text: {
                NO: 'For å definere standard fakturabetalingsdato, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Faktura-panelet. Velg Dokumentets gyldighet og angi ønsket antall dager. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu określenia domyślnego terminu płatności faktury wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Faktura. Wybierz Ważność Dokumentu i ustaw wybraną liczbę dni. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To set the default invoice payment date, select Settings from the main menu. In the Document tab, find the Invoice panel. Select Document Validity and set the desired number of days. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturadata',
                'settingsfakturanr',
                'mainmenu',
            ]
        }, 
        settings_fakturanr: {
            file: settings_fakturanr,
            text: {
                NO: 'For å endre neste fakturadokumentnummer, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Faktura-panelet. Velg Fakturanummer og skriv inn det nye nummeret. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany nr kolejnego dokumentu faktury wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Faktura. Wybierz Faktura nr i wprowadź nowy nr. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the next invoice document number, select Settings from the main menu. In the Document tab, find the Invoice panel. Select Invoice No. and enter the new number. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturadata',
                'settingsfakturadue',
                'mainmenu',
            ]
        },  
        settings_hourprice: {
            file: settings_hourprice,
            text: {
                NO: 'For å endre standard timepris, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Faktura-panelet. Velg Pris per time og still inn. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany domyślnej stawki godzinowej wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Faktura. Wybierz Stawka za godzinę i ustaw. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the default hourly rate, select Settings from the main menu. In the Document tab, find the Invoice panel. Select Hourly Rate and set. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingskmprice',
                'tltofv',
                'mainmenu',
            ]
        },  
        settings_kmprice: {
            file: settings_kmprice,
            text: {
                NO: 'For å endre standardprisen per km, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Faktura-panelet. Velg Pris per km og still inn. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany domyślnej stawki za km wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Faktura. Wybierz Stawka za km i ustaw. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the default rate per km, select Settings from the main menu. In the Document tab, find the Invoice panel. Select Rate per km and set. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingshourprice',
                'tltofv',
                'mainmenu',
            ]
        },  
        settings_language: {
            file: settings_language,
            text: {
                NO: 'Du kan velge språket til Jobleder-appen. Velg Innstillinger fra hovedmenyen. I Konto-fanen finner du Jobberleder-innstillingspanelet og velger Grensesnittspråk. Blant: polsk, engelsk, norsk – velg den som passer deg best og bekreft for å lagre den nye innstillingen.',
                PL: 'Możesz wybrać język aplikacji Jobbleder. Wybierz z menu głównego Ustawienia. W zakładce Konto znajdź panel ustawienia Jobbleder i wybierz Język interfejsu. Spośród: Polski, Angielski, Norweski -wybierz ten najbardziej Ci odpowiadający i potwierdź w celu zapisania nowego ustawienia.',
                EN: 'You can choose the language of the Jobbleder application. Select Settings from the main menu. In the Account tab, find the Jobbleder settings panel and select Interface language. From: Polish, English, Norwegian - choose the one that suits you best and confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingspasschange',
                'mainmenu',
            ]
        },  
        settings_name: {
            file: settings_name,
            text: {
                NO: 'For å endre brukernavnet som er angitt som standard når du fullfører tidslisten, velg Innstillinger fra hovedmenyen. I Konto-fanen finner du Kontoinnstillinger-panelet. Velg Brukernavn. Gjør endringene og bekreft for å lagre det nye navnet ditt.',
                PL: 'Aby zmienić nazwę użytkownika domyślnie wprowadzaną podczas uzupełniania Listy czasu wybierz z menu głównego Ustawienia. W zakładce Konto znajdź panel Ustawienia konta. Wybierz Nazwa użytkownika. Wprowadź zmiany i potwierdź w celu zapisania nowego imienia.',
                EN: 'To change the username entered by default when filling out the Time List, select Settings from the main menu. In the Account tab, find the Account Settings panel. Select Username. Make changes and confirm to save the new name.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturadata',
                'settingstimelistdefault',
                'mainmenu',
            ]
        },
        settings_passchange: {
            file: settings_passchange,
            text: {
                NO: 'For å endre brukerpassordet, velg Innstillinger fra hovedmenyen. I Konto-fanen finner du Kontoinnstillinger-panelet. Velg Passord. Skriv inn gammelt passord, nytt passord og gjenta nytt passord. Bekreft for å lagre endringer.',
                PL: 'Aby zmienić hasło użytkownika wybierz z menu głównego Ustawienia. W zakładce Konto znajdź panel Ustawienia konta. Wybierz Hasło. Wprowadź stare hasło, nowe hasło i powtórz nowe hasło. Potwierdź w celu zapisania zmian.',
                EN: 'For å endre brukerpassordet, velg Innstillinger fra hovedmenyen. I Konto-fanen finner du Kontoinnstillinger-panelet. Velg Passord. Skriv inn gammelt passord, nytt passord og gjenta nytt passord. Bekreft for å lagre endringer.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturadata',
                'settingsname',
                'mainmenu',
            ]
        },  
        settings_tilbuddue: {
            file: settings_tilbuddue,
            text: {
                NO: 'For å definere standard gyldighetsperiode for tilbudet, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du tilbudspanelet. Velg Dokumentets gyldighet og angi ønsket antall dager. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu określenia domyślnego terminu ważności oferty wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Oferta. Wybierz Ważność Dokumentu i ustaw wybraną liczbę dni. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To set the default budget validity date, select Settings from the main menu. In the Document tab, find the Budget panel. Select Document Validity and set the desired number of days. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingstilbudnr',
                'mainmenu',
            ]
        },  
        settings_tilbudnr: {
            file: settings_tilbudnr,
            text: {
                NO: 'For å endre nummeret på neste tilbudsdokument, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du tilbudspanelet. Velg Tilbudsnummer og skriv inn det nye nummeret. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany nr kolejnego dokumentu oferty wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Oferta. Wybierz Oferta nr i wprowadź nowy nr. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the next budget document number, select Settings from the main menu. In the Document tab, find the Budget panel. Select Budget No. and enter the new number. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingstilbuddue',
                'mainmenu',
            ]
        },  
        settings_timelistdefault: {
            file: settings_timelistdefault,
            text: {
                NO: 'Du kan endre standardverdiene for nyopprettede tidslistedokumenter. Velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Timeliste-panelet. Velg alternativet hvis standardverdi du vil endre. Bekreft for å lagre den nye innstillingen.',
                PL: 'Możesz zmienić domyślne wartości dla nowo tworzonych dokumentów listy czasu. Wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Lista godzin. Wybierz opcję której wartość domyślną chcesz zmienić. Potwierdź by zapisać nowe ustawienie.',
                EN: 'You can change the default values for newly created time sheet documents. Select Settings from the main menu. In the Document tab, find the time sheet panel. Select the option whose default value you want to change. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingsname',
                'settingshourprice',
                'settingstimelistnr',
                'mainmenu',
            ]
        },  
        settings_timelistnr: {
            file: settings_timelistnr,
            text: {
                NO: 'For å endre det neste dokumentnummeret i tidslisten, velg Innstillinger fra hovedmenyen. På Dokument-fanen finner du timelistepanelet. Velg Time List No. og angi en ny verdi. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany nr kolejnego dokumentu listy czasu wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Lista czasu. Wybierz Lista czasu nr i wprowadź nową wartość. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the next time sheet document number, select Settings from the main menu. In the Document tab, find the Time sheet panel. Select Time sheet No. and enter the new value. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingsname',
                'settingstimelistdefault',
                'mainmenu',
            ]
        },  
        settings_vat: {
            file: settings_vat,
            text: {
                NO: 'For å endre standard mva-sats, velg Innstillinger fra hovedmenyen. I Dokument-fanen finner du Faktura-panelet. Velg MVA og endre standardsatsen. Bekreft for å lagre den nye innstillingen.',
                PL: 'W celu zmiany domyślnej stawki VAT wybierz z menu głównego Ustawienia. W zakładce Dokument znajdź panel Faktura. Wybierz VAT i zmień stawkę domyślną. Potwierdź by zapisać nowe ustawienie.',
                EN: 'To change the default VAT rate, select Settings from the main menu. In the Document tab, find the Invoice panel. Select VAT and change the default rate. Confirm to save the new setting.',
            },
            type: 'settings',
            buttons: [
                'settingsfakturadata',
                'settingsfakturadue',
                'mainmenu',
            ]
        },        



        //=====================================
        hei_im_elsa: {
            file: false,
            text: {
                NO: 'Hei jeg heter Elsa. Jeg er Laskowski Creatives virtuelle assistent.',
                PL: 'Cześć jestem Elsa. Jestem wirtualną asystenktą Laskowski Creative.',
                EN: 'I\'m Laskowski Creative\'s virtual assistant.',
            },
            type: 'introduce',
            buttons: ['continue', 'mainmenu']
        },
        show_me_what_you_are_interest_in: {
            file: false,
            text: {
                NO: 'Vil du vise meg hva du er interessert i?',
                PL: 'Wskaż co Cię interesuje.',
                EN: 'Show me what you are interested in',
            },
            type: 'helper',
            buttons: [
                'mainview',
                'appmenu',
                'newdocument',
            ]
        },
        mainview_part1: {
            file: timeline_part1,
            text: {
                NO: 'Hovedvisningen lar deg enkelt navigere i dokumentopprettingsprosessen. Menyen er til venstre. På høyre side er det en liste over dokumenter presentert i form av en kalender eller liste. Du kan filtrere dokumenter basert på type tidsliste, tilbud, faktura.',
                PL: 'Widok główny pozwala na łatwą nawigację w procesie tworzenia dokumentu. Menu znajduje się po lewej stronie. Po prawej stronie znajduje się lista dokumentów prezentowana w formie kalendarza lub zestawienia. Możesz filtrować dokumenty w oparciu o rodzaj lista godzin, oferta, faktura.',
                EN: 'The main view allows you to easily navigate the document creation process. The menu is on the left. On the right side there is a list of documents presented in the form of a calendar or list. You can filter documents based on the type of timesheet, quote, invoice.'
            },
            type: 'mainview',
            buttons: ['continue', 'settingslanguage', 'mainmenu']
        },
        mainview_part2: {
            file: timeline_part2,
            text: {
                NO: 'Du kan vise det valgte dokumentet i forhåndsvisningsvinduet til venstre ved å klikke én gang. Ved å klikke på nytt aktiverer du forhåndsvisningen i full skjerm med dokumentalternativer som utskrift til PDF, gjenta dokument, redigering og andre spesialiserte funksjoner for det valgte dokumentet.',
                PL: 'Wybrany dokument możesz obejrzeć w oknie podglądu po lewej stronie klikając raz. Ponowne kliknięcie aktywuje podgląd pełnoekranowy z opcjami dokumentu, takimi jak drukowanie do formatu PDF, powtarzanie dokumentu, edycja i inne wyspecjalizowane funkcje dla wybranego dokumentu.',
                EN: 'You can view the selected document in the preview window on the left by clicking once. Clicking again activates the full-screen preview with document options such as printing to PDF, repeating the document, editing, and other specialized features for the selected document.'
            },
            type: 'mainview',
            buttons: [
                'documentedit', 
                'documentdelete',
                'documentrepeat',
                'savepdf',
                'mainmenu'
            ]
        },
        appmenu: {
            file: menu,
            text: {
                NO: 'Hovedvisningen lar deg enkelt navigere i dokumentopprettingsprosessen. Menyen er til venstre. ',
                PL: 'Widok główny pozwala na łatwe poruszanie się po procesie tworzenia dokumentu. Menu znajduje się po lewej stronie.',
                EN: 'The main view allows you to easily navigate the document creation process. The menu is on the left.'
            },
            type: 'appmenu',
            buttons: ['menu_search', 'menu_addaday', 'menu_newdoc', 'menu_projects', 'menu_clients', 'menu_settings', 'mainmenu']
        },
        appmenu_search: {
            file: menu_search,
            text: {
                NO: 'Søk – en funksjon som lar deg søke i ressurser for å finne en klient, et prosjekt eller et dokument.',
                PL: 'Szukaj – funkcja umożliwiająca wyszukiwanie zasobów w celu znalezienia klienta, projektu lub dokumentu.',
                EN: 'Search – a feature that allows you to search resources to find a client, project or document.'
            },
            type: 'appmenu',
            buttons: ['continue', 'mainmenu']
        },
        appmenu_addaday: {
            file: menu_addaday,
            text: {
                NO: 'Husk timer - registrer arbeidstimer i minnet. Ved systematisk bruk er funksjonen til hjelp ved registrering av arbeidsdager. De innsamlede timene kan enkelt importeres til en tidsliste senere.',
                PL: 'Dniówka – zapisz godziny pracy w pamięci. Funkcja stosowana systematycznie jest pomocna przy rejestrowaniu dni roboczych. Zebrane godziny można później łatwo zaimportować do listy godzin.',
                EN: 'add a day - record working hours in database. When used systematically, the function is helpful in recording working days. The collected hours can be easily imported into a timesheet later.'
            },
            type: 'appmenu',
            buttons: ['continue', 'addaday', 'importday', 'mainmenu']
        },
        appmenu_newdoc: {
            file: menu_newdocument,
            text: {
                NO: 'Lage dokument – legg til et nytt tilbud, tidsliste eller fakturadokument.',
                PL: 'Nowy dokument – ​​dodaj nowy dokument oferty, arkusza czasu pracy lub faktury.',
                EN: 'New Document – ​​add a new budget, timesheet or invoice document.'
            },
            type: 'appmenu',
            buttons: ['continue', 'newinvoice', 'newbudget', 'newtimelist'],
        },
        appmenu_projects: {
            file: menu_projects,
            text: {
                NO: 'Prosjekter – opprett et nytt og vis listen over aktive prosjekter',
                PL: 'Projekty – utwórz nowy i wyświetl listę aktywnych projektów',
                EN: 'Projects – create a new one and view the list of active projects'
            },
            type: 'appmenu',
            buttons: ['continue', 'newproject', 'projectarchive', 'mainmenu']
        },
        appmenu_clients: {
            file: menu_clients,
            text: {
                NO: 'Kunder – opprett en ny og se listen over aktive kunder',
                PL: 'Klienci – utwórz nowy i wyświetl listę aktywnych klientów',
                EN: 'Clients – create a new one and view the list of active customers'
            },
            type: 'appmenu',
            buttons: ['continue', 'newclient', 'clientarchive', 'mainmenu']
        },
        appmenu_settings: {
            file: menu_settings,
            text: {
                NO: 'Innstillinger – åpner innstillingsmenyen',
                PL: 'Ustawienia – otwiera menu ustawień',
                EN: 'Settings – opens the settings menu'
            },
            type: 'appmenu',
            buttons: ['continue', 'settingsfakturadata', 'settingstimelistdefault', 'settingspasschange', 'mainmenu']
        },
        appmenu_logout: {
            file: menu_logout,
            text: {
                NO: 'Logg ut – logg ut brukeren.',
                PL: 'Wyloguj – wylogowuje użytkownika.',
                EN: 'Logout – logs out the user.'
            },
            type: 'appmenu',
            buttons: ['settingspasschange', 'mainmenu']
        },
        newdoc_part1: {
            file: menu_newdocument,
            text: {
                NO: 'For å opprette et lage dokument, velg lage dokument fra menyen. Du kan velge mellom et timelistedokument, et tilbudsdokument og et fakturadokument.',
                PL: 'Aby utworzyć nowy dokument, wybierz z menu nowy dokument. Możesz wybierać pomiędzy dokumentem listy godzin, dokumentem oferty i dokumentem faktury.',
                EN: 'To create a new document, select new document from the menu. You can choose between a timesheet document, a quote document and an invoice document.',
            },
            type: 'newdoc',
            buttons: ['continue', 'newinvoice', 'newbudget', 'newtimelist']
        },
        newdoc_part2: {
            file: new_document_part2,
            text: {
                NO: 'Den øvre linjen informerer om dokumenttype og nummer. Inneholder knapper: minimer dokument, lukk dokument. Den siste knappen bytter mellom veiviservisningen og dokumentvisningen.',
                PL: 'Górna belka informuje o rodzaju i numerze dokumentu. Zawiera przyciski: minimalizuj dokument, zamknij dokument. Ostatni przycisk przełącza pomiędzy widokiem kreatora a widokiem dokumentu.',
                EN: 'The top beam informs about the document type and number. Contains buttons: minimize document, close document. The last button switches between the wizard view and the document view.',
            },
            type: 'newdoc',
            buttons: ['continue', 'documentedit', 'documentdelete', 'documentrepeat', 'mainmenu']
        },
        newdoc_part3: {
            file: menu_newdocument,
            text: {
                NO: 'Du kan opprette 3 dokumenter samtidig - 1 tidsliste, 1 tilbud og 1 faktura.',
                PL: 'Możesz tworzyć jednocześnie 3 dokumenty - 1 listę godzin, 1 ofertę i 1 fakturę.',
                EN: 'You can create 3 documents at the same time - 1 timesheet, 1 quote and 1 invoice.',
            },
            type: 'newdoc',
            buttons: ['newinvoice', 'newbudget', 'newtimelist', 'printdocument', 'savepdf']
        },
        newdoc_fv_part0: {
            file: faktura_part0,
            text: {
                NO: 'For å begynne å lage et nytt fakturadokument, velg lage dokument -> faktura fra menyen.',
                PL: 'Aby rozpocząć tworzenie nowego dokumentu faktury, wybierz Nowy dokument -> Faktura z menu.',
                EN: 'To start creating a new invoice document, select New Document -> Invoice from the menu.'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingsfakturanr', 'settingsfakturadata', 'mainmenu']
        },
        newdoc_fv_part1: {
            file: faktura_part0,
            text: {
                NO: 'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
                PL: 'Kreator przeprowadzi Cię przez 5 kroków procesu:',
                EN: 'The wizard will guide you through 5 steps in the process:'
            },
            type: 'newdoc',
            buttons: ['continue', 'mainmenu']
        },
        newdoc_fv_part2: {
            file: faktura_part2,
            text: {
                NO: '1. Fullføre kundedata',
                PL: '1. Uzupełnij dane klienta',
                EN: '1. Complete customer data'
            },
            type: 'newdoc',
            buttons: ['continue', 'newclient', 'mainmenu']
        },
        newdoc_fv_part3: {
            file: faktura_part3,
            text: {
                NO: '2. Informasjon om prosjektet',
                PL: '2. Informacje o projekcie',
                EN: '2. Project information'
            },
            type: 'newdoc',
            buttons: ['continue', 'newproject', 'mainmenu']
        },
        newdoc_fv_part4: {
            file: faktura_part4,
            text: {
                NO: '3. Utstedelsesdato og utløp av dokumentet',
                PL: '3. Data wydania i wygaśnięcia dokumentu',
                EN: '3. Document issue date and expiration'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingsfakturadue', 'mainmenu']
        },
        newdoc_fv_part5: {
            file: faktura_part5,
            text: {
                NO: '4. Dokumentelementer',
                PL: '4. Pozycje dokumentu',
                EN: '4. Document positions'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingsvat', 'settingshourprice', 'mainmenu']
        },
        newdoc_fv_part6: {
            file: faktura_part6,
            text: {
                NO: '5. Forhåndsvis og lagre det ferdige dokumentet.',
                PL: '5. Wyświetl podgląd i zapisz ukończony dokument.',
                EN: '5. Preview and save the finished document.'
            },
            type: 'newdoc',
            buttons: ['fvtovarsel', 'fvtokreditnota', 'tbtofv', 'tltofv', 'printdocument', 'mainmenu']
        },
        fv_to_kreditnota: {
            file: fv_to_kreditnota,
            text: {
                NO: 'I hovedvisningen, fra dokumentlisten, åpner du fakturaen som skal krediteres. Fra dokumentalternativene, velg Kredittnota og bekreft å opprette et nytt dokument.',
                PL: 'W widoku głównym z listy dokumentów otwórz fakturę do skredytowania. Z opcji dokumentu wybierz Nota kredytowa i potwierdź utworzenie nowego dokumentu.',
                EN: 'In the main view, from the document list, open the invoice to be credited. From the document options, select Credit Note and confirm the creation of the new document.'
            },
            type: 'newdoc',
            buttons: ['fvtovarsel', 'printdocument', 'savepdf', 'mainmenu']
        },
        fv_to_varsel: {
            file: fv_to_varsel,
            text: {
                NO: 'I hovedvisningen, fra listen over dokumenter, åpner du fakturaen du vil utstede en varsel. Fra dokumentalternativene, velg Varsel og bekreft. Klikk på Varsel-ikonet i dokumentalternativene for å laste ned pdf-en.',
                PL: 'W widoku głównym z listy dokumentów otwórz fakturę dla której chcesz wystawić upomnienie. Z opcji dokumentu wybierz Varsel i potwierdź. Klikając teraz w ikonę Varsel w opcjach dokumentu zostanie pobrany pdf.',
                EN: 'In the main view, from the document list, open the invoice for which you want to issue a reminder. From the document options, select Varsel and confirm. Now, by clicking the Varsel icon in the document options, the pdf will be downloaded.'
            },
            type: 'newdoc',
            buttons: ['fvtokreditnota', 'printdocument', 'savepdf', 'mainmenu']
        },        
        newdoc_budget_part0: {
            file: tilbud_part0,
            text: {
                NO: 'For å begynne å lage et nytt tilbuddokument, velg lage dokument -> tilbud fra menyen.',
                PL: 'Aby rozpocząć tworzenie nowego dokumentu oferty, wybierz Nowy dokument -> Oferta z menu.',
                EN: 'To start creating a new budget document, select New Document -> Budget from the menu.'
            },
            type: 'newdoc',
            buttons: ['continue','settingstilbudnr', 'settingsfakturadata', 'mainmenu']
        },
        newdoc_budget_part1: {
            file: tilbud_part0,
            text: {
                NO: 'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
                PL: 'Kreator przeprowadzi Cię przez 5 kroków procesu:',
                EN: 'The wizard will guide you through 5 steps in the process:'
            },
            type: 'newdoc',
            buttons: ['continue', 'mainmenu']
        },
        newdoc_budget_part2: {
            file: tilbud_part2,
            text: {
                NO: '1. Fullføre kundedata',
                PL: '1. Uzupełnij dane klienta',
                EN: '1. Complete customer data'
            },
            type: 'newdoc',
            buttons: ['continue', 'newclient',  'mainmenu']
        },
        newdoc_budget_part3: {
            file: tilbud_part3,
            text: {
                NO: '2. Informasjon om prosjektet',
                PL: '2. Informacje o projekcie',
                EN: '2. Project information'
            },
            type: 'newdoc',
            buttons: ['continue', 'newproject', 'mainmenu']
        },
        newdoc_budget_part4: {
            file: tilbud_part4,
            text: {
                NO: '3. Utstedelsesdato og utløp av dokumentet',
                PL: '3. Data wydania i wygaśnięcia dokumentu',
                EN: '3. Document issue date and expiration'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingstilbuddue', 'mainmenu']
        },
        newdoc_budget_part5: {
            file: tilbud_part5,
            text: {
                NO: '4. Dokumentelementer',
                PL: '4. Pozycje dokumentu',
                EN: '4. Document positions'
            },
            type: 'newdoc',
            buttons: ['continue',  'mainmenu']
        },
        newdoc_budget_part6: {
            file: tilbud_part6,
            text: {
                NO: '5. Forhåndsvis og lagre det ferdige dokumentet.',
                PL: '5. Wyświetl podgląd i zapisz ukończony dokument.',
                EN: '5. Preview and save the finished document.'
            },
            type: 'newdoc',
            buttons: ['tbtofv', 'newinvoice', 'newtimelist', 'printdocument', 'savepdf', 'mainmenu']
        },
        newdoc_timelist_part0: {
            file: timelist_part0,
            text: {
                NO: 'For å begynne å lage et nytt timelistdokument, velg lage dokument -> timelist fra menyen.',
                PL: 'Aby rozpocząć tworzenie nowej listy godzin, wybierz Nowy dokument -> Lista godzin z menu.',
                EN: 'To start creating a new timesheet document, select New Document -> Timesheet from the menu.'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingstimelistnr', 'mainmenu']
        },
        newdoc_timelist_part1: {
            file: timelist_part0,
            text: {
                NO: 'Veiviseren vil lede deg gjennom 5 trinn i prosessen:',
                PL: 'Kreator przeprowadzi Cię przez 5 kroków procesu:',
                EN: 'The wizard will guide you through 5 steps in the process:'
            },
            type: 'newdoc',
            buttons: ['continue', 'mainmenu']
        },
        newdoc_timelist_part2: {
            file: timelist_part2,
            text: {
                NO: '1. Fullføre kundedata',
                PL: '1. Uzupełnij dane klienta',
                EN: '1. Complete customer data'
            },
            type: 'newdoc',
            buttons: ['continue','newclient',  'mainmenu']
        },
        newdoc_timelist_part3: {
            file: timelist_part3,
            text: {
                NO: '2. Informasjon om prosjektet',
                PL: '2. Informacje o projekcie',
                EN: '2. Project information'
            },
            type: 'newdoc',
            buttons: ['continue', 'newproject', 'mainmenu']
        },
        newdoc_timelist_part4: {
            file: timelist_part4,
            text: {
                NO: '3. 3. Ansattes navn',
                PL: '3. Imię pracownika',
                EN: '3. Worker name'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingsname', 'mainmenu']
        },
        newdoc_timelist_part5: {
            file: timelist_part5,
            text: {
                NO: '4. Dokumentelementer',
                PL: '4. Pozycje dokumentu',
                EN: '4. Document positions'
            },
            type: 'newdoc',
            buttons: ['continue', 'settingstimelistdefault', 'addaday', 'importday', 'mainmenu']
        },
        newdoc_timelist_part6: {
            file: timelist_part6,
            text: {
                NO: '5. Forhåndsvis og lagre det ferdige dokumentet.',
                PL: '5. Wyświetl podgląd i zapisz ukończony dokument.',
                EN: '5. Preview and save the finished document.'
            },
            type: 'newdoc',
            buttons: ['tltofv', 'addaday', 'importday','printdocument', 'savepdf', 'mainmenu']
        },
        document_delete: {
            file: document_delete,
            text: {
                NO: 'I hovedvisningen, fra dokumentlisten, åpner du dokumentet du vil slette. Velg Slett fra dokumentalternativene. MERK: Fakturaen kan ikke slettes.',
                PL: 'W widoku głównym z listy dokumentów otwórz dokument, który chcesz usunąć. Z opcji dokumentu wybierz Usuń. UWAGA: Faktur nie można usuwać.',
                EN: 'In the main view, open the document you want to delete from the document list. From the document options, select Delete. NOTE: Invoices cannot be deleted.'
            },
            type: 'newdoc',
            buttons: ['documentedit', 'documentrepeat', 'mainmenu']
        },
        document_edit: {
            file: document_edit,
            text: {
                NO: 'I hovedvisningen, fra dokumentlisten, åpner du dokumentet du vil redigere. Velg Rediger fra dokumentalternativene. Gjør endringene og lagre filen som vanlig.',
                PL: 'W widoku głównym z listy dokumentów otwórz dokument, którego edycje chcesz wykonać. Z opcji dokumentu wybierz Edytuj. Wprowadź zmiany i zapisz plik jak zawsze.',
                EN: 'In the main view, open the document you want to edit from the document list. From the document options, select Edit. Make changes and save the file as usual.'
            },
            type: 'newdoc',
            buttons: ['documentdelete', 'documentrepeat' ,'printdocument', 'savepdf', 'mainmenu']
        },
        document_repeat: {
            file: document_repeat,
            text: {
                NO: 'I hovedvisningen, fra dokumentlisten, åpner du dokumentet du vil bruke som mal for et nytt dokument. Velg Gjenta fra dokumentalternativene. Det nye dokumentet vil inneholde verdiene fra maldokumentet.',
                PL: 'W widoku głównym z listy dokumentów otwórz dokument, który chcesz użyć jako wzór dla nowego dokumentu. Z opcji dokumentu wybierz Powtórz. W nowym dokumencie będą znajdowały się wartości z dokumentu wzorcowego.',
                EN: 'In the main view, open the document you want to use as a template for the new document. From the document options, select Repeat. The new document will contain the values from the template document.'
            },
            type: 'newdoc',
            buttons: ['documentedit', 'documentdelete', 'printdocument', 'savepdf', 'mainmenu']
        },
        newdoc_tl_to_fv: {
            file: timelist_to_faktura,
            text: {
                NO: 'Du kan enkelt utstede en faktura basert på et tidslistedokument. Åpne det valgte tidslistedokumentet, og velg deretter fakturaalternativet fra dokumentmenyen. En vare vil automatisk legges til i fakturadokumentet, som beregner arbeidstiden fra timelisten til timeprisen som er lagret i innstillingene.',
                PL: 'W łatwy sposób możesz wystawić fakturę na podstawie listy czasu. Otwórz wybrany dokument listy czasu, a następnie wybierz opcję faktury z menu dokumentu. Do dokumentu faktury zostanie automatycznie dodana pozycja, która przelicza czas pracy z timelisty przez stawkę godzinową zapisaną w ustawieniach.',
                EN: 'You can easily issue an invoice based on a timesheet document. Open the selected timesheet document, then select the invoice option from the document menu. An item will automatically be added to the invoice document, which calculates the working hours from the timesheet at the hourly rate saved in the settings.'
            },
            type: 'newdoc',
            buttons: ['mainmenu', 'newinvoice', 'settingshourprice', 'settingsfakturadata', 'savepdf']
        },
        newdoc_tb_to_fv: {
            file: tilbud_to_faktura,
            text: {
                NO: 'Du kan enkelt utstede en faktura basert på tilbudsdokumentet. Åpne det valgte tilbudsdokumentet, og velg deretter fakturaalternativet fra dokumentmenyen. Feltene som inngår i tilbudet blir automatisk lagt til i fakturadokumentet.',
                PL: 'Na podstawie dokumentu ofertowego możesz łatwo wystawić fakturę. Otwórz wybrany dokument oferty, a następnie wybierz opcję faktury z menu dokumentu. Pola zawarte w ofercie są automatycznie dodawane do dokumentu faktury.',
                EN: 'You can easily issue an invoice based on the budget document. Open the selected budget document, and then select the invoice option from the document menu. The fields included in the budget will be automatically added to the invoice document.'
            },
            type: 'newdoc',
            buttons: ['mainmenu', 'newinvoice', 'newbudget', 'settingsfakturadata', 'savepdf']
        },
        addaday: {
            file: add_day,
            text: {
                NO: 'Ved systematisk bruk er funksjonen til hjelp ved registrering av arbeidsdager. De innsamlede timene kan enkelt importeres til en tidsliste senere. For å lagre timene du har jobbet, velg husk timer fra menyen. I det viste vinduet skriver du inn informasjon om valgt arbeidsdag. Angi datoen og angi om dagen regnes som ekstra. Angi inn- og utreisetid og lengden på pausen. I beskrivelsesfeltet skriver du inn en kort beskrivelse av arbeidet og bekrefter om all informasjon er korrekt. Når den er lagret riktig, kan dagen importeres til tidslistedokumentet',
                PL: 'Aby zapisać przepracowane godziny, wybierz z menu opcję Zapamiętaj godziny. W wyświetlonym oknie wprowadź informacje o wybranym dniu roboczym. Wprowadź datę i określ, czy dzień jest uważany za dodatkowy. Wprowadź godziny wejścia i wyjścia oraz długość przerwy. W polu opisu wpisz krótki opis pracy i potwierdź, czy wszystkie informacje są prawidłowe. Po prawidłowym zapisaniu dzień można zaimportować do dokumentu listy godzin',
                EN: 'To save the hours you have worked, select remember hours from the menu. In the window that appears, enter information about the selected working day. Enter the date and indicate whether the day is considered extra. Enter the entry and exit times and the length of the break. In the description field, enter a short description of the work and confirm whether all information is correct. Once saved correctly, the day can be imported into the timesheet document.'
            },
            type: 'newdoc',
            buttons: ['mainmenu', 'newtimelist', 'importday']
        },
        importday: {
            file: add_day_to_timelist,
            text: {
                NO: 'Vinduet Importer dag-til-tidslistedokument vises automatisk når du oppretter et dokument. Du kan også åpne vinduet ved å bruke Import-knappen.',
                PL: 'Okno Import dniówki do dokumentu listy czasu pojawia się automatycznie podczas tworzenia dokumentu. Możesz też wywołać je za pomocą przycisku Importuj.',
                EN: 'The Import Days to Time Sheet window appears automatically when you create a document. You can also open the window using the Import button.'
            },
            type: 'newdoc',
            buttons: ['mainmenu', 'newtimelist', 'addaday']
        },


    };

    // ------------ ELSA BUTTONS GENERATE ------
    const buttonArray = elsaSentences[sentence]['buttons'];
    const buttons = buttonArray.map((button, index)=>{
        return getButton(button, lang);
    })

    // --------- REQUESTED ELSA SENTENCE GENERATE -----
    const requestedSentence = {
        text: elsaSentences[sentence]['text'][lang],
        file: elsaSentences[sentence]['file'],
        type: elsaSentences[sentence]['type'],
        buttons,
    }

    // ------- FINALLY RETURN REQUESTED ELSA SENTENCE -----
    return requestedSentence;

}
//---------- ELSA SENTENCES END ----------------- 





//----------------------------- STARA ELSA =======


export{elsa};
